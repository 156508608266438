import React from 'react'

import twitter from "../Images/twitter.png";
import discord from "../Images/discord.png";

function Footer() {
    return (
        <>
            <hr size={3} width="100%" style={{color: '#707070', marginTop: '80px'}} />
            <footer className="container mx-50">
            <div className="row">
                <div className="col-md-4 text-center">
                    <div>
                        <h3 className="color-yellow">
                        GET ON THE LIST
                        </h3>
                        <div className=" ">
                        <form >
                            <input className="box Poppins" type="email"  placeholder="Enter Email"/>
                            <button type="submit" className="emailBtn" ><i className="fa fa-arrow-right" aria-hidden="true"></i></button>
                        </form>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 text-center">
                <a href="http://twitter.com/tuhynft" target="_blank"> <img style={{width: '50px', height: '50px', margin: '20px'}} src={twitter} alt="Italian Trulli" /></a>
                <a href="https://t.co/xHfl29OaI1?amp=1" target="_blank"> <img style={{width: '50px', height: '50px'}} src={discord} alt="Italian Trulli" /> </a>
                </div>
                <div className="col-md-4 text-center">
                <h4 className="color-yellow" style={{ marginTop: 30}}>Terms   Privacy</h4>
                </div>
            </div>
            </footer>
        </>
    )
}

export default Footer
