import React, { useState, useEffect } from "react";
import axios from "axios";
import loaderGIF from "../Images/loaders/loading3.gif"


function GetNFT(props) {
  const [NFT, setNFT] = useState([]);
  const [show, setShow] = useState(false);
  const [IDs, setIDs] = useState([]);

  async function looping() {
    // console.log("looping start");
    props.data.forEach( async (item, i) => {
        const nft = {
          id: item.token_id,
          image: await fetchingImage(item.token_id)
        }

        var a1 = NFT;
        // console.log(a1)
        a1.push(nft)
        setNFT(a1);

        var b1 = IDs;
        // console.log(b1)
        b1.push(item.token_id)
        setIDs(b1)
        if(i == props.data.length-1 ){
          setTimeout(() => {
            setShow(true); 
            setShow(true); 
            setShow(true); 
            
          }, 5000);
          // console.log(props.data.length);
        }
      })
  }
  
  
  async function fetchingImage(id) {
    return await axios.get(`https://api.opensea.io/api/v1/asset/0x0e4245ccb9954ead73f8bab13607cd2d86efc84c/${id}?format=json`)
    .then(data => {
      // console.log(data);
      return data.data.image_url
    })
    .catch(err => console.log);
  }

  useEffect(() => {
    // console.log("useEffect");
    // console.log(props);
    if(props.length !== 0 && show === false){
      // console.log("looop");
      looping();
    }  
  }, [props])


  
  const render = () =>{

    if(show == false) return <div className='loadingGifDiv'> <center> <img src={loaderGIF} alt="loadingGif" /> </center> </div> ;
    
    return(
      <>
        <div className="row mb-5 mx-0" style={{ display: "flex", justifyContent: "space-between"}}>
          <span className="main_heading">Available for Stake</span>
          <button className="btn btn-success stakeAll" onClick={() => props.stakeAll(IDs)}> Stake All </button>
        </div>
        <div className="row">
        {
          NFT.map((nft,i) => {
            // console.log(NFT);
            return(
              <div key={i} className=" col-lg-3 col-md-4 col-sm-6 myCard">
                <div className="card myCard p-3" >
                  <img className="card-img-top img-fluid" src={nft.image} style={{maxWidth: "250px"}} alt="Card image cap" />
                  <div className="card-body">
                    <h5 className="card-title">{nft.id}</h5>
                    <div className="row" style={{ display: "flex", justifyContent: "space-around"}}>
                      <button className="btn btn-primary col-md-5 col-xs-10 mb-2" onClick={() => props.SingleStake(nft.id)} style={{fontWeight: 600}}>Stake</button>
                      <a href={`https://opensea.io/assets/0x0e4245ccb9954ead73f8bab13607cd2d86efc84c/${nft.id}`} className="col-md-5 col-xs-10 mb-2" onClick={() => console.log(nft)} target=" " style={{fontWeight: 600}}>
                        <img src="https://opensea.io/static/images/logos/opensea.svg" alt="opensea" style={{ width: "30px" }} />
                      </a>
                    </div>
                  </div>
                </div>      
              </div>      
              )
            })
          }
        </div>
      </>
    )

  }
  return render()
}

export default GetNFT;