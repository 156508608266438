import React, { useState,useEffect,useRef } from 'react';
import ReactDOM from 'react-dom'
import './App.css'
import $ from "jquery";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/jquery/dist/jquery.min.js';
import Home from "./component/Home";
import stake_nft from "./component/stake-nft";
import FarmLand from "./component/farmLand";
import Team from "./component/team";
import AppRouter from "./route/route";


function App() {
  return (
    <>
      <AppRouter />
    </>    
  );
}
export {App};
