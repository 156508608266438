import React from "react";

import mint from "../Images/Path 154.png";
import twitter from "../Images/twitter.png";
import discord from "../Images/discord.png";
import image10 from "../Images/image (10).png";
import group108 from "../Images/Group 108.png";
import group103 from "../Images/Group 103.png";
import image11 from "../Images/image (11).png";
import image24 from "../Images/image (24).png";
import unity from "../Images/unity.jpg";
import Navbar from "./navbar";


function FarmLand(params) {
    return(
        <>
        <Navbar />


        {/* Welcome to the unstable */}
        <section className="container mx-50">
          <div>
            <div className="row jc-center">
              <div className="col-md-6 col-lg-7 mb-4">
                <h4 style={{color: 'white'}}>10,000 Unstable Horses with revolutionary deflationary 
                  NFTokenomics</h4>
                <h1 style={{color: 'yellow', fontSize: '48px'}}>Welcome to the unstable horses Yard</h1>
                <p style={{color: 'white'}}>Multiple different breeds, farm juice that creates rarer horses, free farmland on the metaverse and more. Yeah, you’re in the right place at the right time!</p>

                <a className="btn btn-outline-danger" href="https://t.co/xHfl29OaI1?amp=1" target="_blank"> Discord </a>
                <a className="btn btn-outline-danger" href="https://t.co/xHfl29OaI1?amp=1" target="_blank" style={{marginLeft: '50px'}}> Twitter </a>

              </div>
              <div className="col-md-6 col-lg-5 text-center">
                <img style={{width: '300px', height: '300px'}} src={image10} alt="Italian Trulli" /> 
              </div>
            </div>
          </div>
        </section>

        {/* unstable description */}
        <section className="container">
          <div className="mx-50 text-center color-white">
            <h1 style={{color: 'white', textAlign: 'center'}}>Welcome to the Unstable 
              Horses Yard</h1>                  
            <p style={{color: 'white', textAlign: 'center'}}>Good to see you. We have worked hard on TUHY to bring you a collection that will have significant benefits beyond collecting and owning some cool art. Beyond the aesthetically pleasing 9,000 unstable horses that are created, we set out with a goal to have TUHY owners with the unlimited ability to regenerate further income, whether through our revolutionary breeding process, our $HORSE DAO (staking), or through rewarding collectors with metaverse farmland. Welcome to the future of NFTs, we hope you've got your farmers hat on.</p>
          </div>
        </section> 

        {/* price */}
        <section className="container">
          <div className="mx-50 text-center color-white text-center ">
            <h1>PRICE + INFO</h1>
            <p>One of the exciting things about Unstable Horses is that although our 10,000 horses are completely unique 1/1s derived from different expressions, hoof, mouth, clothing and more. Although some are rarer than others, we have a revolutionary protocol to generate new (even more rare) Unstable Horses through our breeding center. More info can be found on this here (underline here and have it hyperlinked to the Breeding Centre page) </p>
            <p>The horses are stored as ERC-721 tokens on the Ethereum blockchain and hosted on IPFS. There is horse club benefits which include rewards, farmland opportunities, giveaways, access to our breeding center and more!</p>
          </div>
        </section>         
        {/* horses stay */}
        <section className="container">
          <div className="row mx-50">
            <div className="col-md-4 mb-3">
              <div className="card">
                <h3>HORSES, HERE TO STAY</h3>
                <p style={{marginTop: '25px'}}>No bonding curve. No ramps. Just 0.04ETH per mint.
                  High resolution file included, alongside full usage rights and tons of rewards and benefits. Yeeehaw!</p>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card">
                <h3>BREEDING CENTER</h3>
                <p style={{marginTop: '25px'}}>Ever wondered what would happen if you combined two horses, what they’d look like?
                  Well, you don’t have to wonder anymore. The breeding center allows any owner with two horses to breed, and create a new horse with the features of its parents. This makes your new horse more rare, and the collection more scarce.
                </p>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card">
                <h3>THE YARD (EXCLUSIVE)</h3>
                <p style={{marginTop: '25px'}}>Only TUHY owners allowed!
                  Get access to exclusive discord channels which bring access to members only challenges, rewards, giveaways and perks in the TUHY ecosystem. And believe us, there is going to be a lot to look forward to.</p>
              </div>
            </div>
          </div>
        </section>
        
         {/* mint */}
         <section style={{backgroundColor: '#FFBB00'}} id="mint">
          <div className="container jumbotron mint-bg">
            <div className="row">
              <div className="col-md-5 text-center">
                <img className="fazool" src={group103} alt="Italian Trulli" />
              </div>
              <div className="col-md-7">
                <h1 style={{color: 'black', marginRight: '20px', fontSize: '48px'}}>MINT AN UNSTABLE HORSE FROM THE YARD</h1>
                <p style={{color: 'black', textAlign: 'left'}} className="Poppins">
                  Each unstable horse costs just 0.04ETH, and for that you get the following:
                  
                  <ul className="mt-3">
                    <li className="Poppins">A 1/1 Unstable Horse</li>
                    <li className="Poppins">Access to the $HORSE DAO</li>
                    <li className="Poppins">Access to ‘The Yard’ a members only space where you can win rewards based on challenges and loyalty.</li>
                    <li className="Poppins">Token Airdrop on launch</li>
                    <li className="Poppins">Participation opportunity to own farmland in the metaverse</li>
                    <li className="Poppins">A 3D horse to complete in the rececourses</li>
                    <li className="Poppins">A 3D Horse to compete in <span className="vimland fs18"> Gallop</span> – the 3D game </li>
                  </ul>
                </p>
                <div>
                  <button type="button" className="btn btn-primary p-3 px-5 Poppins" style={{fontWeight: 600, textTransform: "uppercase"}}>Coming Soon</button>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        
        {/* charity & fund */}
        <section className="container mx-50">
          <div>
            <div className="row jc-center">
              <div className="col-md-6 col-lg-7 mb-4 order-md-1 order-2">
                <h1 style={{color: 'white', fontSize: '48px'}}>Charity </h1>
                <p style={{color: 'white'}}>The Unstable Horses Yard came from a genuine love of horses (one of our team Mustang takes care of her own horses currently). Due to this, we wanted to give back as our project grows to some of the amazing organisations making a difference to horses around the world. We are speaking to various organisations currently in regards to this and will update our community as soon as we have any confirmation!</p>
                <h3 style={{color: 'white', fontSize: '48px'}}>Fund </h3>
                <p style={{color: 'white'}}>If you’d like to contribute to the community fund, we appreciate and thank you in advance. The community fund is literally for the community, and will be used completely for the purposes of marketing, building out cool features, and more. Heres the cool part….any amount spent will be 100% decided by YOU! Thats right, if you own a TUHY, you will have a say in the direction and what the funds are spent on.</p>
              </div>
              <div className="col-md-6 col-lg-5 text-center order-md-2 order-1">
                <img style={{width: '300px', height: '300px'}} src={image10} alt="Italian Trulli" /> 
              </div>
            </div>
          </div>
        </section>
        
        <section className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-3 d-flex jc-center ">
              <div className="card2">
                <img src={image11} alt="Avatar" style={{width: '290px'}} />
                <h3 className="color-white mt-4">HORSES, HERE TO STAY</h3>
                <p className="px-2" style={{marginTop: '25px', color: 'white'}}>No bonding curve. No ramps. Just 0.04ETH per mint.
                  High resolution file included, alongside full usage rights and tons of rewards and benefits. Yeeehaw!</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-3 d-flex jc-center ">
              <div className="card2">
                <img src={image24} alt="Avatar" style={{width: '290px'}} />
                <h3 className="color-white mt-4">BREEDING CENTER</h3>
                <p className="px-2" style={{marginTop: '25px', color: 'white'}}>Ever wondered what would happen if you combined two horses, what they’d look like?
                  Well, you don’t have to wonder anymore. The breeding center allows any owner with two horses to breed, and create a new horse with the features of its parents. This makes your new horse more rare, and the collection more scarce.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-3 d-flex jc-center ">
              <div className="card2">
                <img src={image24} alt="Avatar" style={{width: '290px'}} />
                <h3 className="color-white mt-4">THE YARD (EXCLUSIVE)</h3>
                <p className="px-2" style={{marginTop: '25px', color: 'white'}}>Only TUHY owners allowed!
                  Get access to exclusive discord channels which bring access to members only challenges, rewards, giveaways and perks in the TUHY ecosystem. And believe us, there is going to be a lot to look forward to.</p>
              </div>
            </div>
          </div>
        </section>
       
         {/* discord */}
         <section className="jumbotron mint-bg mx-50">
          <div className=" text-center container">
            <h1 className="color-black" >JOIN OUR DISCORD</h1>
            <p className="color-black" >We are looking for Unstable Horse Enthusiasts to help us conquer the world.</p>
            <a className="btn btn-primary p-2 px-4 Poppins" style={{ borderRadius: 30}} href="https://t.co/xHfl29OaI1?amp=1" target="_blank"> JOIN OUR DISCORD </a>            
          </div>
        </section>

        {/* about team */}

        <section className="container mx-50">
          
          {/* Roadmap */}
          <div className="row mx-50">
            <div className="text-center">
              <h1 className="color-yellow" style={{fontSize: '48px'}}>TUHY Roadmap</h1>
              <p className="color-white Poppins">Following the recent worldwide pandemic, emerging reports suggest that several banana species have begun exhibiting strange characteristics. Our research team located across the globe has commenced efforts to study and document these unusual phenomena.
                Concerned about parties trying to suppress our research, the team has opted to store our findings on the blockchain to prevent interference. Although this is a costly endeavour, our mission has never been clearer.
                The fate of the world's bananas depends on it.
                Your support in this time of need is greatly appreciated!</p>
            </div>
          </div>
        </section>

        
        <section className="container">
          <div className="row jc-center">
            <div className="col-2 disNone">
              <div style={{color: 'white'}} className="circle">1</div>
              <div style={{color: 'white'}} className="circle">2</div>
              <div style={{color: 'white'}} className="circle">3</div>
              <div style={{color: 'white'}} className="circle">4</div>
              <div style={{color: 'white'}} className="circle">5</div>
            </div>
            <div className="col-10">
              <div className="i">
                
                <div className="boxModel">
                  <p style={{color: '#857B6A', fontSize: 25}} className="vimland color-yellow">Phase 1</p>
                <ul>
                  <li style={{color: "white"}}>
                    <p style={{color: 'white'}} className="Poppins"> We hire a group of community moderators, and extend our social outreach beyond the stables.  Community fund initiated. Town hall is built (for voting). Early adopters will get, alongside an early collector badge on discord.</p>
                  </li>
                </ul>
                </div>
                <div className="boxModel">
                  <p style={{color: '#857B6A', fontSize: 25}} className="vimland color-yellow">Phase 2</p>
                  <ul>
                    <li style={{color: "white"}}>
                      <p style={{color: 'white'}} className="Poppins">Charity monthly donation % will be confirmed and distributed. TUHY breeding launch (create new unique horses). Weekly competition for the best community designed horse with ETH giveaways.</p>
                    </li>
                  </ul>
                </div>
                <div className="boxModel">
                  <p style={{color: '#857B6A', fontSize: 25}} className="vimland color-yellow">Phase 3</p>
                  <ul>
                    <li style={{color: "white"}}>
                      <p style={{color: 'white'}} className="Poppins">Initial Merch drop for the farmer degens. $HORSE token+ DAO launch. Airdropped tokens to all TUHY owners. </p>
                    </li>
                  </ul>
                <br />
                </div>
                <div className="boxModel">
                  <p style={{color: '#857B6A', fontSize: 25}} className="vimland color-yellow">Phase 4</p>
                  <ul>
                    <li style={{color: "white"}}>
                      <p style={{color: 'white'}} className="Poppins">Loyalty rewards initiated through our revolutionary Farmlands feature - get land for free by being loyal to your horses. Significant further marketing push.</p>
                    </li>
                  </ul>
                </div>
                <div className="boxModel">
                  <p style={{color: '#857B6A', fontSize: 25}} className="vimland color-yellow">Phase 5 <img src={unity} alt="" width="100px" className="ml-3" /></p>
                  <ul>
                    <li style={{color: "white"}}>
                      <p style={{color: 'white'}} className="Poppins">
                      Its game time! We launch GALLOP – a is a blockchain-based racing platform, where you take your Unstable Horse to the racecourse against thousands of others for on and off chain rewards. Built with Unity using the in-house TUHY NFT Game Framework, the project offers the opportunity for owners to race on different terrains, in the most advanced game the NFT space has ever seen.                         </p>
                    </li>
                  </ul>
                <br />  
                </div>
              </div>
            </div>
          </div>
        </section>
        


      {/* footer */}
        

      <hr size={3} width="100%" style={{color: '#707070', marginTop: '80px'}} />
        <footer className="container mx-50">
          <div className="row">
            <div className="col-md-4 text-center">
              <div>
                <h3 className="color-yellow">
                  GET ON THE LIST
                </h3>
                <div className=" ">
                  <form >
                    <input className="box Poppins" type="email"  placeholder="Enter Email"/>
                    <button type="submit" className="emailBtn" ><i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <a href="http://twitter.com/tuhynft" target="_blank"> <img style={{width: '50px', height: '50px', margin: '20px'}} src={twitter} alt="Italian Trulli" /></a>
              <a href="https://t.co/xHfl29OaI1?amp=1" target="_blank"> <img style={{width: '50px', height: '50px'}} src={discord} alt="Italian Trulli" /> </a>
            </div>
            <div className="col-md-4 text-center">
              <h4 className="color-yellow" style={{ marginTop: 30}}>Terms   Privacy</h4>
            </div>
          </div>
        </footer>
        
        </>
    )
}

export default FarmLand