import React ,{ useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../index.css";
import mint from "../Images/Path 154.png";
import twitter from "../Images/twitter.png";
import discord from "../Images/discord.png";
import logo from "../Images/logo.png";
import Home from "./Home";
import stake_nft from "./stake-nft";
import FarmLand from "./farmLand";
import Team from "./team";
import Web3 from 'web3';


function Navbar() {

  const [logAdd, setlogAdd] = useState("Connect Wallet");

  async function load() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
      .then((result) => {

        var str = result[0];
        var start5 = str.substring(0, 5)
        var middle5 = ".....";
        var last5 = str.substring(37, 42);
        var joined = start5 + middle5 + last5

        setlogAdd(joined)
        // window.location.reload();
        // console.clear() 
      }).catch((err) => {
        console.log(err);
      });

    }
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    }
    else {
      // window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
    }

  }

  window.addEventListener('load', (event) => {
      load()
      // console.clear() 

  });

  if (window.ethereum) {
    window.ethereum.on('accountsChanged', function (accounts) {
      load()
      // console.clear() 
    })

  }else if (window.web3) {
    window.web3 = new Web3(window.web3.currentProvider)
    window.ethereum.on('accountsChanged', function (accounts) {
      load()
      // console.clear() 
    })

  }

  useEffect(() => {
    load();
  }, [])

  return(
        <>
         {/* navbar */}
       <section className="container">
          <nav style={{color: 'brown'}} className="navbar navbar-expand-lg  navbar-dark bg-transparent" id="na">
            <Link className="navbar-brand mr-5" to="/">
              <img className="logo-custom max-w-190" src={logo} style={{ width: 50 }} alt=""/>
            </Link>

            <button className="navbar-toggler" data-toggle="collapse" data-target="#collapse_target">
              <span className="navbar-toggler-icon" />
            </button>

            <div className="collapse navbar-collapse nav-collapse-custom  jc-sb" id="collapse_target">
              <ul className="navbar-nav">
                <li className="nav-item vimland">
                  <Link  className="nav-link color-yellow vimlandnav" aria-current="page" to="/">OverView</Link>
                </li>
                <li className="nav-item vimland" onClick={() => window.location.replace("/#yard")}>
                  <a className="nav-link  color-yellow vimlandnav" href="#yard">The Yard</a>
                </li>
                {/* <li className="nav-item vimland" onClick={() => window.location.replace("/#mint")}>
                  <Link  className="navbar-link" aria-current="page" to="/#mint"> <img style={{width: '60px', height: '30px'}} src={mint} alt="Italian Trulli" /> </Link>
                </li> */}
                {/* <li className="nav-item vimland">
                  <Link className="nav-link color-yellow vimlandnav" to="#" >FarmLand</Link>
                </li> */}
                {/* <li className="nav-item vimland" onClick={() => window.location.replace("/")}>
                  <a className="nav-link  color-yellow vimlandnav" href="#team">Team</a>
                </li> */}
                {/* <li className="nav-item vimland" onClick={() => window.location.replace("/#charity")}>
                  <a className="nav-link  color-yellow vimlandnav" href="#charity">Charity</a>
                </li> */}
                <li className="nav-item vimland" >
                  {/* <a className="nav-link  color-yellow vimlandnav" href="#reward">Reward</a> */}
                  <Link className="nav-link color-yellow vimlandnav" to="/claim" >Claim</Link>
                </li>
                <li className="nav-item vimland" >
                  {/* <a className="nav-link  color-yellow vimlandnav" href="#reward">Reward</a> */}
                  <Link className="nav-link color-yellow vimlandnav" to="/stake-horse" >Stake $HORSE</Link>
                </li>
                <li className="nav-item vimland">
                  <Link  className="nav-link color-yellow vimlandnav" aria-current="page" to="/stake-nft">Stake NFTs </Link>
                </li>
              </ul>
              <div className="d-flex">
                <button className="btn btn-primary px-3 color-yellow vimlandnav" onClick={()=>load()} style={{ borderRadius: 30, minWidth: 150, fontSize: 18}} target="_blank"> { logAdd } </button>
                <a className="nav-link " href="http://twitter.com/tuhynft" target="_blank"> <img style={{width: '20px', height: '20px'}} src={twitter} alt="Italian Trulli" /></a>
                <a className="nav-link " href="https://discord.gg/DAGPpg6BEv" target="_blank"> <img style={{width: '20px', height: '30px'}} src={discord} alt="Italian Trulli" /></a>
                <a className="nav-link " href="https://opensea.io/collection/the-unstable-horses-yard" target="_blank"> <img style={{width: '20px', height: '30px'}} src="https://opensea.io/static/images/logos/opensea.svg" alt="" /></a>
              </div>
            </div>
          </nav>
        </section>
        
        </>
        
  )
    
}

export default Navbar