import React from "react";
import twitter from "../Images/twitter.png";
import discord from "../Images/discord.png";
import image11 from "../Images/image (11).png";
import image12 from "../Images/image (12).png";
import Navbar from "./navbar";


function Team() {
    return(
        <>
         <Navbar />
            {/* sec 2 */}
        <section className="container mx-50">
          <div className="row">
            <div className="col-md-6 text-center">
              <img style={{width: '300px', height: '300px'}} src={image11} alt="Italian Trulli" /> 
            </div>
            <div className="col-md-6">
              <h1 style={{color: 'white', fontSize: '48px'}}>Whats the breeding 
                center?</h1>
            </div>
          </div>  
        </section>
        {/* sec 3 */}
        <section className="container mx-50">
          <div className="row">
            <div className="col-md-6 order-md-1 order-2">
              <h1 style={{color: 'white', fontSize: '48px'}}>Glad you asked! Well in short</h1>
              <p style={{color: 'white'}}>the breeding center brings a unique opportunity where you can take two of your Unstable Horses that you own, and breed them (through burning both), to generate a unique, new, rare, never before seen Unstable Horse! All you need to do is own a farm juice in your wallet and apply for the breeding center. More on this below.
              </p>
            </div>
            <div className="col-md-6 text-center order-md-2 order-1">
              <img style={{width: '300px', height: '300px'}} src={image12} alt="Italian Trulli" /> 
            </div>
          </div>  
        </section>
        {/* sec 4 */}
        <section className="container mx-50">
          <div className="row">
            <div className="col-md-6 text-center">
              <img style={{width: '300px', height: '300px'}} src={image11} alt="Italian Trulli" /> 
            </div>
            <div className="col-md-6">
              <h2 style={{color: 'white'}}>Firstly, lets outline the advantages and 
                disadvantages of this (see, we have 
                done the analysis for you):</h2>
              <p style={{color: 'white'}}>+ Create a rarer Unstable Horse
                + By Burning 2 Horses, you lessen the supply of Unstable Horses in the yard
                + You become a breeder, bringing exclusive benefits and rewards in the near future
                -You have to essentially ‘burn’ 2 of your horses to get one
                -You need to have a farm juice in your wallet to initiate the breeding
              </p>
            </div>
          </div>  
        </section>
     
     
        {/* faq */}
     
      
        <div className="mb-5">
          <br />
          <br />
          <center>
            <h2 className="price-Heading color-yellow vimland" style={{fontSize: '48px'}} id="faq">FAQs</h2>
          </center>
          <br />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">


                <div className="panel panel-default  faq-ques">
                  <div className="panel-heading" role="tab" id="headingOne">
                    <h4 className="panel-title ">
                      <a className="collapsed vimland" style={{fontSize: 25}} data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="false" aria-controls="collapseTwo">
                      When does The Unstable Horses Yard Launch?                      </a>
                    </h4>
                  </div>
                  <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                    <div className="panel-body Poppins">
                    We have a provisional date in place, but this may change. Please stay connected to our community on discord for further updates on launch date and time!                    </div>
                  </div>
                </div>

                <div className="panel panel-default  faq-ques">
                  <div className="panel-heading" role="tab" id="headingTwo">
                    <h4 className="panel-title ">
                      <a className="collapsed vimland" style={{fontSize: 25}} data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      How can I buy one and how many can I mint at once?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                    <div className="panel-body Poppins">
                                            On launch day, there will be a mint button that will appear to replace the ‘Coming Soon’ button
                              that may currently show. Then, you simply click mint, connect your metamask wallet (please
                              use a desktop / laptop when doing so), and then you can go ahead. There is a limit of 20
                              Unstable Horses you can mint in any one transaction however.                   
                        </div>
                  </div>
                </div>

                <div className="panel panel-default  faq-ques">
                  <div className="panel-heading" role="tab" id="headingTwo">
                    <h4 className="panel-title ">
                      <a className="collapsed vimland" style={{fontSize: 25}} data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseTwo">
                      What is the price at launch?
                      </a>
                    </h4>
                  </div>
                  <div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                    <div className="panel-body Poppins">
                    The price will be 0.04ETH and will remain that price throughout the minting period. No fomo
                    ramps, and no bonding curves.                     </div>
                  </div>
                </div>
               
                <div className="panel panel-default  faq-ques">
                  <div className="panel-heading" role="tab" id="headingTwo">
                    <h4 className="panel-title ">
                      <a className="collapsed vimland" style={{fontSize: 25}} data-toggle="collapse" data-parent="#accordion" href="#collapsefour" aria-expanded="false" aria-controls="collapseTwo">
                      What is the the charity and community fund goal?
                      </a>
                    </h4>
                  </div>
                  <div id="collapsefour" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                    <div className="panel-body Poppins">
                        We wanted to do more than just create a cool generative art collection. Genuinely, two of our
                        team members breed horses and thus, we wanted to ensure that we are makning positive noise
                        in the space by partnering with a charity and donating a % of the community fund monthly to
                        them. This will all be referenced and explained through our discord. <br />
                        Our community fund goal is not based on money. Its based on the DAO, and the voting power
                        and decision making process that owners have. With our 1 horse = 1 vote mechanism, it brings
                        us a fair decision making process that values those who matter most – the owners.                    
                    </div>
                  </div>
                </div>
                <div className="panel panel-default  faq-ques">
                  <div className="panel-heading" role="tab" id="headingThree">
                    <h4 className="panel-title">
                      <a className="collapsed vimland" style={{fontSize: 25}} data-toggle="collapse" data-parent="#accordion" href="#collapse5" aria-expanded="false" aria-controls="collapse5">
                      What is the breeding center?
                      </a>
                    </h4>
                  </div>
                  <div id="collapse5" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                    <div className="panel-body Poppins">
                        Our community fund goal is not based on money. Its based on the DAO, and the voting power
                        and decision making process that owners have. With our 1 horse = 1 vote mechanism, it brings
                        us a fair decision making process that values those who matter most – the owners.                      </div>
                    </div>
                </div>
                <div className="panel panel-default  faq-ques">
                  <div className="panel-heading" role="tab" id="headingThree">
                    <h4 className="panel-title">
                      <a className="collapsed vimland" style={{fontSize: 25}} data-toggle="collapse" data-parent="#accordion" href="#collapse6" aria-expanded="false" aria-controls="collapseThree">
                      Can you explain what ‘Farm Juice’ is?

                      </a>
                    </h4>
                  </div>
                  <div id="collapse6" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                    <div className="panel-body Poppins">
                    So we wanted to create layers in our TUHY ecosystem as we feel there will be many who may
                    not necessarily want to breed their horses. So, in order to breed two horses, you must have a
                    ‘Farm Juice’ in your wallet. This does not expire or get ‘used’ once someone breeds, but, there
                    are only 500 available. Simply speaking, if you own a large supply of the Farm Juices, farmers
                    (owners), will have to come to you for one! <br />
                    To make it fair, the Farm juice will either be airdropped to 500 lucky owners or, distributed on
                    Opensea at a minimal amount for those who wish to purchase it. 

                       </div>
                  </div>
                </div>
                <div className="panel panel-default  faq-ques">
                  <div className="panel-heading" role="tab" id="headingThree">
                    <h4 className="panel-title">
                      <a className="collapsed vimland" style={{fontSize: 25}} data-toggle="collapse" data-parent="#accordion" href="#collapse7" aria-expanded="false" aria-controls="collapseThree">
                      What is the $HORSE token?
                      </a>
                    </h4>
                  </div>
                  <div id="collapse7" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                    <div className="panel-body Poppins">
                    One of the cool things about the TUHY ecosystem is that by owning an Unstable Horse, you
                    automatically will be part of the token launch through getting an airdrop of tokens. These
                    tokens can be traded or stored for your own investment purposes, but acts as a genuine reward
                    for owning a horse.                      </div>
                  </div>
                </div>

                <div className="panel panel-default  faq-ques">
                  <div className="panel-heading" role="tab" id="headingThree">
                    <h4 className="panel-title">
                      <a className="collapsed vimland" style={{fontSize: 25}} data-toggle="collapse" data-parent="#accordion" href="#collapse8" aria-expanded="false" aria-controls="collapseThree">
                      What is Yard Staking?
                      </a>
                    </h4>
                  </div>
                  <div id="collapse8" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                    <div className="panel-body Poppins">
                    As much as we understand those who want to buy and sell quickly for profit, we appreciate that
                    the core of the community are those that buy and hold for a significant period of time. So, we
                    will be introducing staking, where an owner can stake their horse in the yard, and through our
                    protocol, yield $HORSE tokens. If at any point the owner would like to unstake or sell their
                    horse, they are welcome to do so too. But the cool thing is genuinely getting yield and residual
                    income from simply holding! More on the launch of this and detail can be found in our discord. 
                    </div>
                  </div>
                </div>
               
                <div className="panel panel-default  faq-ques">
                  <div className="panel-heading" role="tab" id="headingThree">
                    <h4 className="panel-title">
                      <a className="collapsed vimland" style={{fontSize: 25}} data-toggle="collapse" data-parent="#accordion" href="#collapse9" aria-expanded="false" aria-controls="collapseThree">
                      How do I know the rarity of my Unstable Horse and what are breeds?
                      </a>
                    </h4>
                  </div>
                  <div id="collapse9" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                    <div className="panel-body Poppins">
                    Soon after minting is complete, we will be revealing the rarity + the traits in full to the
                    community. However, there is additional benefits beyond rarity that will play a role in the value
                    of your Unstable Horse. <br />
                    The different breeds acts as a significant factor, with different breeds getting different benefits
                    on and off chain, alongside access to different challenges through our roadmap. 
                      </div>
                  </div>
                </div>

                <div className="panel panel-default  faq-ques">
                  <div className="panel-heading" role="tab" id="headingThree">
                    <h4 className="panel-title">
                      <a className="collapsed vimland" style={{fontSize: 25}} data-toggle="collapse" data-parent="#accordion" href="#collapse10" aria-expanded="false" aria-controls="collapseThree">
                      What is the farmlands feature? 
                      </a>
                    </h4>
                  </div>
                  <div id="collapse10" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                    <div className="panel-body Poppins">
                    One of the cool things we wanted to ensure we implement, is genuine value beyond the
                    original art collection. So, we will be airdropping and offering farmland from the metaverse to
                    lucky owners spontaneously, or through larger competitions. More on this will be explained
                    through our discord.
                      </div>
                  </div>
                </div>

             

                <div className="panel panel-default  faq-ques">
                  <div className="panel-heading" role="tab" id="headingOne">
                    <h4 className="panel-title ">
                      <a className="collapsed vimland" style={{fontSize: 25}} data-toggle="collapse" data-parent="#accordion" href="#collapse11" aria-expanded="false" aria-controls="collapseTwo">
                      What if I have further questions about the project?
                       </a>
                    </h4>
                  </div>
                  <div id="collapse11" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                    <div className="panel-body Poppins">
                      Feel free to hit us up on twitter or discord. Across the team, we are practically online 24 hours a
                      day, 7 days a week!                      
                    </div>
                  </div>
                </div>

                <div className="panel panel-default  faq-ques">
                  <div className="panel-heading" role="tab" id="headingOne">
                    <h4 className="panel-title ">
                      <a className="collapsed vimland" style={{fontSize: 25}} data-toggle="collapse" data-parent="#accordion" href="#collapse12" aria-expanded="false" aria-controls="collapseTwo">
                         What is the GALLOP all about?
                       </a>
                    </h4>
                  </div>
                  <div id="collapse12" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                    <div className="panel-body Poppins">
                    Sure. Well, there are 10,000 Unstable Horses bred at launch, so, we will have an online racing game in our ecosystem known as GALLOP, where farm owners can race their horses against other owners. What determines the success of your horse winning races depends on the attributes, the breed and the terrain the race is on. More will be announced in regards to this through our discord.                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>



       
       
       
      {/* footer */}
        

      <hr size={3} width="100%" style={{color: '#707070', marginTop: '80px'}} />
        <footer className="container mx-50">
          <div className="row">
            <div className="col-md-4 text-center">
              <div>
                <h3 className="color-yellow">
                  GET ON THE LIST
                </h3>
                <div className=" ">
                  <form >
                    <input className="box Poppins" type="email"  placeholder="Enter Email"/>
                    <button type="submit" className="emailBtn" ><i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <a href="http://twitter.com/tuhynft" target="_blank"> <img style={{width: '50px', height: '50px', margin: '20px'}} src={twitter} alt="Italian Trulli" /></a>
              <a href="https://t.co/xHfl29OaI1?amp=1" target="_blank"> <img style={{width: '50px', height: '50px'}} src={discord} alt="Italian Trulli" /> </a>
            </div>
            <div className="col-md-4 text-center">
              <h4 className="color-yellow"></h4> 
              <h4 className="color-yellow" style={{ marginTop: 30}}>Terms   Privacy</h4>
            </div>
          </div>
        </footer>
                  
        
        </>
    )
}

export default Team