import React from 'react';
import { Redirect, BrowserRouter, BrowserRouter as Router,Route,Switch } from "react-router-dom";
import stake_nft from '../component/stake-nft';
import FarmLand from '../component/farmLand';
import Home from '../component/Home';
import Team from '../component/team';
import Reward from '../component/reward';
import Stake from '../component/Stake';


function AppRouter() {
    return(
    <BrowserRouter> 
     <Router>
     <Route exact path='/' component={Home} />
     <Route path='/team' component={Team} />
     <Route path='/claim' component={Reward} />
     <Route path='/farmland' component={FarmLand} />
     <Route path="/stake-horse" component={Stake}/>
     <Route path='/stake-nft' component={stake_nft} />
     {/* <Route path="*" component={Home} /> */}
     <Redirect to="/" />
     </Router>
    </BrowserRouter>
    )
    
}

export default AppRouter